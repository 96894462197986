<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Evaluar empleado</span>
	  		    <v-spacer></v-spacer>

            <v-btn
              class="mr-2"
              small dark color="green"
              @click="exportExcel( evaluaciones  , 'evaluaciones_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="orange"
              dark
              class="mr-2"
              @click="dialog = true"
              small
              tile
            >
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="evaluaciones"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>

							    <template v-slot:item.revision="{ item }">
							    	Yahaira Sarahi Mendoza Ruiz
							    </template>

							    <template v-slot:item.estatus="{ item }">
							    	<v-chip color="grey"  dark small v-if="item.estatus == 0">Pendiente</v-chip>
							    	<v-chip color="blue" dark small v-if="item.estatus == 1">En proceso</v-chip>
							    	<v-chip color="black" dark small v-if="item.estatus == 2">Rechazado</v-chip>
							    	<v-chip color="green" dark small v-if="item.estatus == 3">Completo</v-chip>
							    </template>


							    <template v-slot:item.actions="{ item }">
							    	<v-btn
							    		color="primary"
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn
							    		color="error"
							    		x-small
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.id_salon }}</strong>
          </span>
        </v-card-title>

        <v-card-text class="black--text">

        	<label><b>Formato de Evaluación de Desempeño Laboral</b></label>
        	<br/>
        	<label><b>Inofmración del Evaluado</b></label>

          <v-row class="mt-2">
          	<v-col cols="12">
	  		  		<v-autocomplete
	  		  			filled
	  		  			dense
	  		  			clearable
	  		  			v-model="editedItem.id_usuario"
	  		  			:items="usuarios"
								label="Selecciona un usuario"
								hide-details
								item-text="datos_completos"
								item-value="id_usuario"
								:readonly="editedIndex >= 0 ? true : false"
							>
							</v-autocomplete>
          	</v-col>

          	<v-col cols="12" md="6">
	  		  		<v-text-field
	            	label="Puesto"
	            	v-model="editedItem.puesto"
	            	filled
	            	dense
	            	hide-details
	            	readonly
	            ></v-text-field>
          	</v-col>


          	<v-col cols="12">
	  		  		<v-autocomplete
	  		  			filled
	  		  			dense
	  		  			clearable
	  		  			v-model="editedItem.id_evaluador"
	  		  			:items="usuarios"
								label="Evaluador"
								hide-details
								item-text="datos_completos"
								item-value="id_usuario"
								readonly
								:readonly="editedIndex >= 0 ? true : false"
							>
							</v-autocomplete>
          	</v-col>

          	<v-divider class="mx-2"></v-divider>

          	<v-col cols="12">
	          	<span v-if="infoUsuario"><b>Fecha alta:</b> {{ infoUsuario.fecha_alta }}</span>
	          	<br/>
	          	<span v-if="infoUsuario"><b>Con prestaciones:</b> {{ infoUsuario.imss ? 'SI' : 'NO' }}</span>
	          	<br/>
	          	<span v-if="infoUsuario && ![19,10,18].includes( editedItem.idpuesto )"><b>Sueldo:</b> ${{ infoUsuario.sueldo_mensual.toFixed(2) }}</span>
	          	<span v-if="editedItem.idpuesto == 19 && infoUsuario"><b>Categoría Recepción:</b> {{ infoUsuario.tipo_pago }} </span>
	          	<span v-if="editedItem.idpuesto == 10 && infoUsuario"><b>Categoría Teacher:</b> {{ infoUsuario.idcategorias_maestro }} </span>
	          	<br/>
          	</v-col>


          	<v-col cols="12">
          		<label><b>Motivo de evaluación</b></label>
              <v-radio-group
					      v-model="editedItem.modo_evaluacion"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="Tiempo en la empresa"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="Desempeño"
					        :value="2"
					      ></v-radio>
					    </v-radio-group>
          	</v-col>

          	<v-divider class="mx-2"></v-divider>


          	<v-col cols="12">
          		<label><b>Criterios de Evaluación</b></label>
          		<br/>
          		<v-textarea
          			v-model="editedItem.criterio_evaluacion"
          			label="Escribe el objetivo del puesto a evaluar"
          			filled
          			dese
          			hide-details
          			:rows="2"
          			auto-grow
								:readonly="editedIndex >= 0 ? true : false"
          		></v-textarea>
          	</v-col>

          	<v-col cols="12">
							<label><b>Lee cuidadosamente cada uno de los aspectos a evaluar y selecciona la puntuación según las aptitudes del evaluado.</b></label>
							<br/>
							<br/>
							<label><b>Puntuación: 1 (Muy malo), 2 (Malo), 3 (Regular), 4 (Bueno), 5 (Excelente).</b></label>
							<br/>
							<br/>
							<span class="text-h6">1-. Competencias generales</span>
							<br/>
							<br/>
							<label><b>Responsabilidad</b></label><br/>
							Cumple con las responsabilidades y tareas asignadas de manera oportuna.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat1_p1"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Puntualidad</b></label><br/>
							Asiste y cumple con los horarios establecidos de manera consistente.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat1_p2"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Trabajo en Equipo</b></label><br/>
							Colabora eficazmente con compañeros y contribuye al ambiente laboral.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat1_p3"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Comunicación</b></label><br/>
							Se comunica de manera clara y efectiva con colegas, clientes y superiores.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat1_p4"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Adaptabilidad</b></label><br/>
							Se ajusta bien a cambios en las tareas o en el ambiente laboral.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat1_p5"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Proactividad</b></label><br/>
							Toma la iniciativa para mejorar procesos o resolver problemas.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat1_p6"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

          	</v-col>

          	<v-divider class="mx-2"></v-divider>

          	<!-- Evaluación recepcioniesta -->
          	<v-col cols="12" v-if="editedItem.idpuesto == 19">
							<span class="text-h6">2. Competencias Específicas por Puesto</span>
							<br/>
							<br/>
							<label><b>Atención al Cliente</b></label><br/>
							Brinda atención cortés y eficiente a los clientes en todo momento.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p1"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Apertura y Cierre de Sucursal</b></label><br/>
							Realiza de manera puntual y responsable las tareas relacionadas con la apertura y cierre de la sucursal.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p2"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Organización</b></label><br/>
							Mantiene la recepción ordenada y documentos clasificados adecuadamente.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p3"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Gestión de Cobranza</b></label><br/>
							Gestiona el cobro de pagos en la sucursal, dando seguimiento a los pagos pendientes y recordatorios a los alumnos.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p4"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Comunicación Efectiva</b></label><br/>
							Comunicarse claramente con estudiantes, padres y el personal.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p5"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>
          	</v-col>

          	<!-- Evaluación recepcioniesta -->
          	<v-col cols="12" v-if="editedItem.idpuesto == 10">
							<span class="text-h6">2. Competencias Específicas por Puesto</span>
							<br/>
							<br/>
							<label><b>Ejecución del Plan de Estudio</b></label><br/>
							Imparta las clases siguiendo el plan de estudio definido.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p6"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Evaluación Académica</b></label><br/>
							Realiza evaluaciones precisas y da seguimiento adecuado al progreso.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p7"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Manejo de Estudiantes</b></label><br/>
							Mantiene un ambiente de aprendizaje motivador y respetuoso.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p8"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Manejo del Tiempo en las Clases</b></label><br/>
							Habilidad para gestionar el tiempo de clase de manera eficiente, asegurando que se cubran todos los temas programados.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p9"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Comunicación Efectiva con los Estudiantes</b></label><br/>
							Capacidad para comunicarse de manera clara y efectiva con los estudiantes, transmitiendo las ideas y conceptos de forma comprensible
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p10"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>
          	</v-col>

          	<!-- Evaluación recepcioniesta -->
          	<v-col cols="12" v-if="editedItem.idpuesto == 18">
							<span class="text-h6">2. Competencias Específicas por Puesto</span>
							<br/>
							<br/>
							<label><b>Gestión de Personal</b></label><br/>
							Coordina eficazmente al equipo y supervisa el cumplimiento de tareas.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p11"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Resolución de Problemas</b></label><br/>
							Identifica y soluciona problemas de manera oportuna y efectiva.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p12"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Cumplimiento de Objetivos de Ventas</b></label><br/>
							Cumplir y superar los objetivos de ventas establecidos, gestionando estrategias que impulsen el desempeño de la sucursal.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p13"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Control Operativo</b></label><br/>
							Gestiona eficazmente las operaciones diarias, asegurando que los procesos internos funcionen sin interrupciones.
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p14"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>

					    <br/>
							<label><b>Servicio al Cliente</b></label><br/>
							Capacidad para brindar un servicio al cliente excepcional, resolviendo dudas, quejas y proporcionando una experiencia positiva
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p15"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>
          	</v-col>

          	<!-- Evaluación administrativa -->
          	<v-col cols="12" v-if="![19,10,18].includes( editedItem.idpuesto )">
							<span class="text-h6">2. Competencias Específicas por Puesto</span>
							<br/>
							Coloca el nombre y descripción de 5 criterios de evaluación apegado al kpi del puesto correspondiente.
							<br/>
					    <label><b>Criterio 1</b></label>
							<v-text-field
							  label="Competencia"
							  filled
							  dense
							  v-model="editedItem.cat2_p16_c"
							></v-text-field>

							<v-textarea
          			v-model="editedItem.cat2_p16_d"
          			label="Descripción"
          			filled
          			dese
          			hide-details
          			:rows="2"
          			auto-grow
          		></v-textarea>
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p16"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>
					    <br/>

					    <label><b>Criterio 2</b></label>
					    <v-text-field
							  label="Competencia"
							  filled
							  dense
							  v-model="editedItem.cat2_p17_c"
							></v-text-field>

							<v-textarea
          			v-model="editedItem.cat2_p17_d"
          			label="Descripción"
          			filled
          			dese
          			hide-details
          			:rows="2"
          			auto-grow
          		></v-textarea>
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p17"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>
					    <br/>

					    <label><b>Criterio 3</b></label>
					    <v-text-field
							  label="Competencia"
							  filled
							  dense
							  v-model="editedItem.cat2_p18_c"
							></v-text-field>

							<v-textarea
          			v-model="editedItem.cat2_p18_d"
          			label="Descripción"
          			filled
          			dese
          			hide-details
          			:rows="2"
          			auto-grow
          		></v-textarea>
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p18"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>
					    <br/>

					    <label><b>Criterio 4</b></label>

					    <v-text-field
							  label="Competencia"
							  filled
							  dense
							  v-model="editedItem.cat2_p19_c"
							></v-text-field>

							<v-textarea
          			v-model="editedItem.cat2_p19_d"
          			label="Descripción"
          			filled
          			dese
          			hide-details
          			:rows="2"
          			auto-grow
          		></v-textarea>
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p19"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>
					    <br/>

					    <label><b>Criterio 5</b></label>
					    <v-text-field
							  label="Competencia"
							  filled
							  dense
							  v-model="editedItem.cat2_p20_c"
							></v-text-field>

							<v-textarea
          			v-model="editedItem.cat2_p20_d"
          			label="Descripción"
          			filled
          			dese
          			hide-details
          			:rows="2"
          			auto-grow
          		></v-textarea>
							<br/>
							<v-radio-group
					      v-model="editedItem.cat2_p20"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="3"
					        :value="3"
					      ></v-radio>
					      <v-radio
					        label="4"
					        :value="4"
					      ></v-radio>
					      <v-radio
					        label="5"
					        :value="5"
					      ></v-radio>
					    </v-radio-group>
          	</v-col>

          	<v-divider class="mx-2"></v-divider>

          	<!-- Evaluación recepcioniesta -->
          	<v-col cols="12">
							<span class="text-h6">3. Justificación</span>
							<br/>
							<br/>

							<v-row>
								<v-col cols="2">Mes</v-col>
								<v-col cols="10">KPI</v-col>

								<v-col cols="2">1</v-col>
								<v-col cols="10">
									<v-text-field
									  filled
									  dense
									  v-model="editedItem.cat3_kpi1"
										:readonly="editedIndex >= 0 ? true : false"
									></v-text-field>
								</v-col>

								<v-col cols="2">2</v-col>
								<v-col cols="10">
									<v-text-field
									  filled
									  dense
									  v-model="editedItem.cat3_kpi2"
										:readonly="editedIndex >= 0 ? true : false"
									></v-text-field>
								</v-col>

								<v-col cols="2">3</v-col>
								<v-col cols="10">
									<v-text-field
									  filled
									  dense
									  v-model="editedItem.cat3_kpi3"
										:readonly="editedIndex >= 0 ? true : false"
									></v-text-field>
								</v-col>

							</v-row>
          	</v-col>

          	<v-divider class="mx-2"></v-divider>

          	<v-col cols="12">

          		<v-textarea
          			v-model="editedItem.eval_critica_jefe"
          			label="Evaluación crítica de jefe directo"
          			filled
          			dese
          			:rows="2"
          			auto-grow
								:readonly="editedIndex >= 0 ? true : false"
          		></v-textarea>

          		<label>Dictamen final</label>
          		<v-textarea
          			v-model="editedItem.dictamen_final"
          			label="Describe la compensación del evaluado en base a la evaluación realizada"
          			filled
          			dese
          			hide-details
          			:rows="2"
          			auto-grow
								:readonly="editedIndex >= 0 ? true : false"
          		></v-textarea>

          	</v-col>

          	<v-col cols="12">
          		<div class="text-subtitle-1" ><b>Promedio final: {{ editedItem.promedio }}</b></div>
          	</v-col>

          	<v-divider class="mx-2"></v-divider>

          	<!-- Evaluación recepcioniesta -->
          	<v-col cols="12" v-if="editedItem.idpuesto == 19">
							<span class="text-h6">4-. Compensaciones</span>
							<br/>
							<br/>
							<label><b>Prestaciones de ley</b></label><br/>
							<br/>
							<v-radio-group
					      v-model="editedItem.prestaciones"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="SI"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="No"
					        :value="2"
					      ></v-radio>

					    </v-radio-group>

					    <br/>
							<label><b>Sueldo y comisiones</b></label><br/>
							<br/>
							<v-radio-group
					      v-model="editedItem.sueldo_recep"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="Cat 1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="Cat 2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="Cat 3"
					        :value="3"
					      ></v-radio>
					    </v-radio-group>
						</v-col>

						<v-col cols="12" v-if="editedItem.idpuesto == 18">
							<span class="text-h6">4-. Compensaciones</span>
							<br/>
							<br/>
							<label><b>Prestaciones de ley</b></label><br/>
							<br/>
							<v-radio-group
					      v-model="editedItem.prestaciones_enc"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="sI"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="No"
					        :value="2"
					      ></v-radio>

					    </v-radio-group>

					    <br/>
							<label><b>Sueldo y comisiones</b></label><br/>
							<br/>
							<v-radio-group
					      v-model="editedItem.sueldo_enc"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="Cat 1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="Cat 2"
					        :value="2"
					      ></v-radio>
					    </v-radio-group>
						</v-col>

						<v-col cols="12" v-if="editedItem.idpuesto == 10">
							<span class="text-h6">4-. Compensaciones</span>
							<br/>
							<br/>
							<label><b>Sueldo</b></label><br/>
							<br/>
							<v-radio-group
					      v-model="editedItem.sueldo_teacher"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="Cat 1"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="Cat 2"
					        :value="2"
					      ></v-radio>
					      <v-radio
					        label="Cat 3"
					        :value="3"
					      ></v-radio>
					    </v-radio-group>
						</v-col>


						<v-col cols="12" v-if="![19,10,18].includes( editedItem.idpuesto )">
							<span class="text-h6">4-. Compensaciones</span>
							<br/>
							<br/>
							<label><b>Prestaciones de ley</b></label><br/>
							<br/>
							<v-radio-group
					      v-model="editedItem.prestaciones_admin"
					      row
								:readonly="editedIndex >= 0 ? true : false"
					    >
					      <v-radio
					        label="sI"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="No"
					        :value="2"
					      ></v-radio>

					    </v-radio-group>

					    <br/>
							<label><b>Sueldo propuesto</b></label><br/>
							<br/>
							<v-text-field
							  type="number"
							  filled
							  dense
							  v-model="editedItem.sueldo_admin"
								:readonly="editedIndex >= 0 ? true : false"
							></v-text-field>
						</v-col>

          </v-row>

          <v-divider></v-divider>

          <v-col cols="12">
          	<v-checkbox
				      v-model="contrato"
				      label="Contrato"
				      dense
				      readonly
				    ></v-checkbox>

				    <v-checkbox
				      v-model="categoria"
				      label="Categoria"
				      dense
				      readonly
				    ></v-checkbox>

				    <v-checkbox
				      v-model="categoria_teacher"
				      label="Categoria T"
				      dense
				      readonly
				      v-if="editedItem.idpuesto == 10"
				    ></v-checkbox>

				    <v-checkbox
				      v-model="sueldo"
				      label="Sueldo"
				      dense
				      readonly
				    ></v-checkbox>

				    <v-checkbox
				      v-model="imss"
				      label="IMSS"
				      dense
				      readonly
				    ></v-checkbox>
          </v-col>

          <v-divider></v-divider>


          <v-row v-if="[1,334,966].includes( getdatosUsuario.iderp )">

          	<label class="mt-12">Selecciona una opción para la evaluación</label>
          	<v-col cols="12">
          		<v-radio-group
					      v-model="editedItem.estatus"
					      row
					    >
					      <v-radio
					        label="Aceptar"
					        :value="1"
					      ></v-radio>
					      <v-radio
					        label="Rechazar"
					        :value="2"
					      ></v-radio>
					    </v-radio-group>
          	</v-col>

          	<v-col cols="12" v-if="editedItem.estatus == 2">
          		<v-textarea
          			v-model="editedItem.motivo_rechazo"
          			label="motivo de rechazo"
          			rows="3"
          			auto-grow
          			filled
          			dense
          			hide-details
          		></v-textarea>
          	</v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            tile
            dark
            small
            @click="close"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            tile
            dark
            small
            @click="save"
            v-if="!botonAprobar"
          >
            <v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>

          <v-btn
            color="green darken-1"
            class=""
            tile
            dark
            small
            @click="save2"
            v-else
          >
            <v-icon small left>mdi-content-save</v-icon>
            Completar proceso
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      planteles:[],

      editedIndex: -1,

      contrato: 0,
      categoria: 0,
      categoria_teacher: 0,
      sueldo: 0,
      imss: 0,

      editedItem: {
      	idevaluacion_empleado:0,
	      id_usuario: 0,
				puesto: 0,
				fecha_evaluacion: 0,
				id_evaluador: 0,
				modo_evaluacion: 0,
				criterio_evaluacion: 0,
				cat1_p1: 0,
				cat1_p2: 0,
				cat1_p3: 0,
				cat1_p4: 0,
				cat1_p5: 0,
				cat1_p6: 0,
				idpuesto: 0,
				cat2_p1: 0,
				cat2_p2: 0,
				cat2_p3: 0,
				cat1_p4: 0,
				cat1_p5: 0,
				cat2_p6: 0,
				cat2_p7: 0,
				cat2_p8: 0,
				cat2_p9: 0,
				cat2_p10: 0,
				cat2_p11: 0,
				cat2_p12: 0,
				cat2_p13: 0,
				cat2_p14: 0,
				cat2_p15: 0,
				cat2_p16_c: 0,
				cat2_p16_d: 0,
				cat2_p16: 0,
				cat2_p17_c: 0,
				cat2_p17_d: 0,
				cat2_p17: 0,
				cat2_p18_c: 0,
				cat2_p18_d: 0,
				cat2_p18: 0,
				cat2_p19_c: 0,
				cat2_p19_d: 0,
				cat2_p19: 0,
				cat2_p20_c: 0,
				cat2_p20_d: 0,
				cat2_p20: 0,
				cat3_kpi1: 0,
				cat3_kpi2: 0,
				cat3_kpi3: 0,
				eval_critica_jefe: 0,
				dictamen_final: 0,
				deleted: 0,
				fecha_creacion: null,
				prestaciones:0,
				sueldo_recep:0,
				prestaciones_enc:0,
				sueldo_enc:0,
				sueldo_teacher:0,
				prestaciones_admin:0,
				sueldo_admin:0,
				estatus: 0,
				motivo_rechazo: null,
				promedio: 0,
      },

      defaultItem: {
      	idevaluacion_empleado:0,
	      id_usuario: 0,
				puesto: 0,
				fecha_evaluacion: 0,
				id_evaluador: 0,
				modo_evaluacion: 0,
				criterio_evaluacion: 0,
				cat1_p1: 0,
				cat1_p2: 0,
				cat1_p3: 0,
				cat1_p4: 0,
				cat1_p5: 0,
				cat1_p6: 0,
				idpuesto: 0,
				cat2_p1: 0,
				cat2_p2: 0,
				cat2_p3: 0,
				cat1_p4: 0,
				cat1_p5: 0,
				cat2_p6: 0,
				cat2_p7: 0,
				cat2_p8: 0,
				cat2_p9: 0,
				cat2_p10: 0,
				cat2_p11: 0,
				cat2_p12: 0,
				cat2_p13: 0,
				cat2_p14: 0,
				cat2_p15: 0,
				cat2_p16_c: 0,
				cat2_p16_d: 0,
				cat2_p16: 0,
				cat2_p17_c: 0,
				cat2_p17_d: 0,
				cat2_p17: 0,
				cat2_p18_c: 0,
				cat2_p18_d: 0,
				cat2_p18: 0,
				cat2_p19_c: 0,
				cat2_p19_d: 0,
				cat2_p19: 0,
				cat2_p20_c: 0,
				cat2_p20_d: 0,
				cat2_p20: 0,
				cat3_kpi1: 0,
				cat3_kpi2: 0,
				cat3_kpi3: 0,
				eval_critica_jefe: 0,
				dictamen_final: 0,
				deleted: 0,
				fecha_creacion: null,
				prestaciones:0,
				sueldo_recep:0,
				prestaciones_enc:0,
				sueldo_enc:0,
				sueldo_teacher:0,
				prestaciones_admin:0,
				sueldo_admin:0,
				estatus: 0,
				motivo_rechazo: null,
				promedio: 0,
      },

      // Filtros:
      incio:null,
			fin:null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      evaluaciones: [],
      headers: [
        { text: 'ID'             , value: 'idevaluacion_empleado' },
        { text: 'Evaluador'      , value: 'evaluador'             },
        { text: 'Empleado'       , value: 'empleado'              },
        { text: 'Puesto'         , value: 'puesto'                },
        { text: 'En revisión por', value: 'revision'              },
        { text: 'Estatus'        , value: 'estatus'               },
        { text: 'Fecha'          , value: 'fecha_creacion'        },
        { text: 'Actions'        , value: 'actions', sortable: false },
      ],

			usuarios:[],

			usuarioSeleccionado: {
				idpuesto: 0,
				puesto: ''
			},

			infoUsuario: null,

			botonAprobar: false


    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar salón' : 'Editar salón'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      "editedItem.id_usuario"( ){

      	if( this.editedIndex >= 0 ){

      		// this.editedItem.puesto       = existeUsuario ? existeUsuario.puesto : ''
	      	// this.editedItem.idpuesto     = existeUsuario ? existeUsuario.idpuesto : ''
	      	// this.editedItem.id_evaluador = existeUsuario ? this.getdatosUsuario.iderp : ''

      	}else{

	      	const existeUsuario = this.usuarios.find( el => el.id_usuario == this.editedItem.id_usuario )

	      	this.editedItem.puesto       = existeUsuario ? existeUsuario.puesto : ''
	      	this.editedItem.idpuesto     = existeUsuario ? existeUsuario.idpuesto : ''
	      	this.editedItem.id_evaluador = existeUsuario ? this.getdatosUsuario.iderp : ''

      	}


      	this.getInfoEmpleado( )

      }

    },

    async created () {
      await this.initialize()
      await this.getUsuarios()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.evaluaciones = []
        return this.$http.get('evaluaciones.empleado.get/' + this.getdatosUsuario.iderp ).then(response=>{
        	this.evaluaciones = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.evaluaciones.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.evaluaciones.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('evaluaciones.empleado.eliminar/' + this.editedItem.idevaluacion_empleado, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getInfoEmpleado( ) {
      	this.cargar = true
        // Lo mandapos por el EP
        this.$http.get('evaluaciones.info.empleado/' + this.editedItem.id_usuario ).then(response=>{

        	if( this.editedItem.id_usuario > 0 ){
        		this.infoUsuario = response.data

        		if( this.editedItem.estatus == 1 ){
	        		this.contrato          = this.editedItem.contrato
							this.categoria         = this.infoUsuario.idcategorias_encargadas ? this.infoUsuario.idcategorias_encargadas : false
							this.categoria_teacher = this.infoUsuario.idcategorias_maestro    ? this.infoUsuario.idcategorias_maestro    : false
							this.sueldo            = this.infoUsuario.sueldo_mensual          ? this.infoUsuario.sueldo_mensual          : false
							this.imss              = this.infoUsuario.rebaje_prestaciones     ? this.infoUsuario.rebaje_prestaciones     : false

							// sueldo_recep
							// sueldo_teacher
							// sueldo_admin

							if( this.editedItem.idpuesto == 18 && this.contrato ){

								if( this.editedItem.prestaciones_enc && this.imss ){ this.botonAprobar      = true }else{ this.botonAprobar = false; this.imss = false }
								// if( this.editedItem.idcategorias_encargadas && this.categoria && ( this.editedItem.idcategorias_encargadas == this.categoria )){ this.botonAprobar      = true }

							}else if( this.editedItem.idpuesto == 19 && this.contrato ){

								if( this.editedItem.prestaciones && this.imss ){ this.botonAprobar      = true }else{ this.botonAprobar = false; this.imss = false }
								if( this.editedItem.sueldo_recep && this.categoria && ( this.editedItem.sueldo_recep == this.categoria )){ this.botonAprobar      = true }else{ this.botonAprobar = false; this.categoria = false }

							}else if( this.editedItem.idpuesto == 10 && this.contrato ){

								if( this.editedItem.prestaciones && this.imss ){ this.botonAprobar      = true }else{ this.botonAprobar = false; this.imss = false }

							}else if( this.contrato ){

								if( this.editedItem.prestaciones_admin && this.imss ){ this.botonAprobar      = true }else{ this.botonAprobar = false; this.imss = false }
								if( this.editedItem.sueldo_admin       && this.sueldo && ( this.editedItem.sueldo_admin == this.sueldo ) ){ this.botonAprobar  = true }else{ this.botonAprobar = false; this.sueldo = false }

							}else{
								this.contrato          = false
								this.botonAprobar      = false
							}

        		}else{
        			this.contrato          = false
        			this.botonAprobar      = false
        		}



        	}



        	console.log( this.infoUsuario, this.botonAprobar, this.contrato, this.imss, this.editedItem.prestaciones_admin, this.editedItem.sueldo_admin, this.sueldo  )
        	this.cargar = true
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
      	if(this.editedItem.horario == ''){
      		this.validarErrorDirecto('Favor de llenar todos los campos')
      	}
      	// Cargamos al usuario que hizo el cambio
	      this.cargar = true

        if (this.editedIndex > -1) {

        	if( this.editedItem.estatus == 2 && !this.editedItem.motivo_rechazo){
        		return this.validarErrorDirecto('Ingresa un motivo de rechazo por favor')
        	}
	        // Lo mandapos por el EP
          this.$http.put('evaluaciones.empleado.update/' + this.editedItem.idevaluacion_empleado, this.editedItem).then(response=>{
          	this.validarSuccess( response.data.message )
        		this.close()
          	this.initialize()
	        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

        } else {
	        // Lo mandapos por el EP
          this.$http.post('evaluaciones.empleado.add', this.editedItem).then(response=>{
        		this.close()
          	this.initialize()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })
        }
      },

      save2 () {
	      this.cargar = true

      	this.editedItem.estatus = 3

        // Lo mandapos por el EP
        this.$http.put('evaluaciones.empleado.update/' + this.editedItem.idevaluacion_empleado, this.editedItem).then(response=>{
        	this.validarSuccess( response.data.message )
      		this.close()
        	this.initialize()
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })

      },

      getPlanteles () {
      	this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
        	this.planteles = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getUsuarios( ){
      	this.usuarios = []
    		this.$http.get('usuarios.all.erp').then(response=>{
    			for(const i in response.data){
    				response.data[i].datos_completos = response.data[i].datos_completos.toUpperCase()
    			}
    			this.usuarios = response.data
    		}).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },
    },
  }
</script>


